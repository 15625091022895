import React, { useEffect, useState } from 'react';
import { AbiItem, fromWei, toWei } from 'web3-utils';
import Web3 from 'web3';
import IStreamingFarmAbi from './abi/IStreamingFarm.json';
import IERC20Abi from './abi/IERC20.json';
import FarmModel from './model/FarmModel';
import farmMIVAWXDAI from './images/farm-MIVA-WXDAI.svg';
import farmMIVAHNY from './images/farm-MIVA-HNY.svg';
import farmMIVAFRACTION from './images/farm-MIVA-FRACTION.svg';
import farmFRACTIONMIVA from './images/farm-FRACTION-MIVA.svg';
import farmSYMMFRACTION from './images/farm-SYMM-FRACTION.svg';
import farmMIVASYMM from './images/farm-MIVA-SYMM.svg';
import {useWeb3React} from "@web3-react/core";
import {TransactionReceipt} from "web3-core/types";
import ERC721Abi from "./abi/ERC721.json";


const rpc = process.env.REACT_APP_CHAIN_RPC_READ || process.env.REACT_APP_CHAIN_RPC || '';
const gasPrice = process.env.REACT_APP_GAS_PRICE || '';

const printError = (e: any): void => {
  console.error(e);
  if (e instanceof Object && e.hasOwnProperty('message')) {
    alert(`Error: ${e.message}`);
  } else {
    alert(e);
  }
};

interface FarmProps {
  web3: Web3 | null;
  farmModel: FarmModel;
  checkNfts: () => void;
}

function Farm(props: FarmProps): JSX.Element {
  const {
    web3, farmModel, checkNfts,
  } = props;
  const { account } = useWeb3React();
  const readWeb3 = new Web3(rpc);
  const [stakeAmount, setStakeAmount] = useState<string>('0');
  const [approved, setApproved] = useState<boolean>(false);
  const [balance, setBalance] = useState<string>('?');
  const [stream, setStream] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [streamTxHash, setStreamTxHash] = useState<string|undefined>(undefined);
  const [streamLogId, setStreamLogId] = useState<number|undefined>(undefined);

  useEffect(() => {
    checkBalance();
    checkAllowance();
    fetchStreamTxHash();
  }, [account]);

  useEffect(() => {
    checkBalance();
    checkAllowance();
  }, [stakeAmount]);

  const checkAllowance = async () => {
    if (!account) {
      setApproved(false);
      return;
    }

    try {
      const lpContract = new readWeb3.eth.Contract(IERC20Abi as AbiItem[], farmModel.stakingToken);// as IERC20;
      const b = await lpContract.methods.balanceOf(account).call();
      setBalance(b);
      const allowance = await lpContract.methods.allowance(account, farmModel.farmAddr).call();
      if (!Number.isNaN(parseFloat(stakeAmount))) {
        setApproved(parseInt(allowance, 10) >= parseInt(toWei(stakeAmount), 10));
      } else {
        setApproved(true);
      }
    } catch (error) {
      setApproved(false);
      console.error(error);
    }
  };

  const checkBalance = async () => {
    if (!account) {
      setBalance('?');
      return;
    }

    try {
      const lpContract = new readWeb3.eth.Contract(IERC20Abi as AbiItem[], farmModel.stakingToken);// as IERC20;
      const b = await lpContract.methods.balanceOf(account).call();
      setBalance(b);
    } catch (error) {
      setBalance('?');
      console.error(error);
    }
  };

  const fetchStreamTxHash = async () => {
    if (!account) {
      return;
    }
    const nftContract = new readWeb3.eth.Contract(ERC721Abi as AbiItem[], farmModel.farmNft);// as ERC721;
    const transferEvents = (await nftContract.getPastEvents('Transfer', {
      fromBlock: 'earliest'
    })).filter((e) => e.returnValues.to.toLowerCase() === account?.toLowerCase()
      || e.returnValues.from.toLowerCase() === account?.toLowerCase());
    const fT = transferEvents[0];
    if (fT) {
      setStreamTxHash(fT.transactionHash);

      // fetch log id via thegraph
      const resp = await fetch("https://api.thegraph.com/subgraphs/name/superfluid-finance/protocol-dev-xdai", {
        "headers": {
          "content-type": "application/json",
        },
        "body":
          "{\"query\":\"{\\n  flowUpdatedEvents(where:{\\n    sender:\\\"" +
          farmModel.farmAddr + "\\\"\\n    receiver:\\\"" + account + "\\\"\\n    transactionHash: \\\"" +
          fT.transactionHash + "\\\"\\n  }){\\n    id\\n   }\\n}\",\"variables\":null}",
        "method": "POST",
      });
      const respJson = await resp.json();
      const idString = respJson.data.flowUpdatedEvents[0].id;
      setStreamLogId(idString.split("-").pop());
    }
  };

  const changeStakeAmount = async (amount: string) => {
    setStakeAmount(amount);
    // uint256 refVal = amount * rewardToken.balanceOf(address(stakingToken)) / stakingToken.totalSupply();

    const rewardTokenContract = new readWeb3.eth.Contract(IERC20Abi as AbiItem[], farmModel.rewardToken);
    const stakingTokenLpBalance = await rewardTokenContract.methods.balanceOf(farmModel.stakingToken).call();

    const stakingTokenContract = new readWeb3.eth.Contract(IERC20Abi as AbiItem[], farmModel.stakingToken);
    const stakingTokenSupply = await stakingTokenContract.methods.totalSupply().call();

    const refVal = (parseInt(stakingTokenLpBalance, 10) * parseFloat(amount)) / parseInt(stakingTokenSupply, 10);
    const interestPerWeek = parseInt(farmModel.rewardSchedule[0][1], 10) / 1000000;
    console.log('interestPerWeek', interestPerWeek);
    setStream(refVal * interestPerWeek);
  };

  const approve = async () => {
    if (!web3 || !account) {
      alert('no web3 or no account.');
      return;
    }
    setLoading(true);
    const largeNumber = '1000000000000000000000000000';
    try {
      const lpContract = new web3.eth.Contract(IERC20Abi as AbiItem[], farmModel.stakingToken);// as IERC20;
      await lpContract.methods.approve(farmModel.farmAddr, largeNumber).send({ from: account, gasPrice });

      await checkAllowance();
    } catch (e: any) {
      printError(e);
    } finally {
      setLoading(false);
    }
  };

  const stake = async () => {
    if (!web3 || !account) {
      alert('no web3 or no account.');
      return;
    }
    setLoading(true);
    try {
      const farmContract = new web3.eth.Contract(IStreamingFarmAbi as AbiItem[], farmModel.farmAddr);// as IStreamingFarm;
      await farmContract.methods.stake(toWei(stakeAmount)).send({from: account, gasPrice});

      setStakeAmount('0');
      setStream(0);
      await checkAllowance();
      await checkNfts();
      setLoading(false);
    } catch (e: any) {
      printError(e);
    } finally {
      setLoading(false);
    }
  };

  const stakingGreaterThanBalance = (): boolean => {
    if (Number.isNaN(parseInt(balance, 10))
      || Number.isNaN(parseFloat(fromWei(balance)))
      || Number.isNaN(parseFloat(stakeAmount))) {
      return false;
    }
    return parseFloat(stakeAmount) > parseFloat(fromWei(balance));
  }

  const currentFlowrate = (): number => {
    return 1 - (parseInt(farmModel.remainingFlowrate, 10) / parseInt(farmModel.maxFlowrate, 10));
  }

  return (
    <div className="farm">





      <div>
        <h3>{`${farmModel.token0Symbol}:${farmModel.token1Symbol}`} <span>Streaming Farm</span></h3>
        <p className="contract">
          <a
            href={`https://blockscout.com/xdai/mainnet/address/${farmModel.farmAddr}/transactions`}
            title={`${farmModel.token0Symbol}:${farmModel.token1Symbol} Streaming Farm Contract`}
            target="_blank"
            rel="noreferrer"
          >
            {farmModel.farmAddr}
          </a>
        </p>
        <div className="tokenSymbols">
          <div className="tokenSymbol1" id={`${farmModel.token0Symbol}`}></div>
          <div className="tokenSymbol2" id={`${farmModel.token1Symbol}`}></div>
        </div>

        {(farmModel.factory === '0xB3BcDA669B49B624862E7489De3c202E0E6CBA10' || farmModel.factory ==='0x5c41E116C162923aF27aa86Ba6dC2299f0Da313c') && (
          <div className="symmetric"></div>
        )}

        {farmModel.factory === '0xA818b4F111Ccac7AA31D0BCc0806d64F2E0737D7' && (
          <div className="honeyswap"></div>
        )}

        <p className="stats">
          <strong>{farmModel.nftSupply}</strong> Farming NFT issued  |  LP Tokens in farm: <strong>{Number.isNaN(parseInt(farmModel.farmLpBalance, 10)) ? '?' : parseFloat(fromWei(farmModel.farmLpBalance)).toFixed(2)}</strong>
        </p>

        <figure>
            {farmModel.token0Symbol === 'MIVA' && farmModel.token1Symbol === 'WXDAI' ? (
              <img src={farmMIVAWXDAI} alt="MIVA:WXDAI Farm" width="300" height="300" />
            ) : ( <></> ) }

            {farmModel.token0Symbol === 'MIVA' && farmModel.token1Symbol === 'HNY' ? (
              <img src={farmMIVAHNY} alt="MIVA:HNY Farm" width="300" height="300" />
            ) : ( <></> ) }

            {farmModel.token0Symbol === 'FRACTION' && farmModel.token1Symbol === 'MIVA' ? (
              <img src={farmFRACTIONMIVA} alt="FRACTION:MIVA Farm" width="300" height="300" />
            ) : ( <></> ) }

            {farmModel.token0Symbol === 'MIVA' && farmModel.token1Symbol === 'SYMM' ? (
              <img src={farmMIVASYMM} alt="MIVA:SYMM Farm" width="300" height="300" />
            ) : ( <></> ) }

            {farmModel.token0Symbol === 'MIVA' && farmModel.token1Symbol === 'FRACTION' ? (
              <img src={farmMIVAFRACTION} alt="MIVA:SYMM FRACTION" width="300" height="300" />
            ) : ( <></> ) }


            {farmModel.token0Symbol === 'SYMM' && farmModel.token1Symbol === 'FRACTION' ? (
              <img src={farmSYMMFRACTION} alt="FRACTION:SYMM Farm" width="300" height="300" />
            ) : ( <></> ) }

        </figure>

        <div className="textWrap">

        <div className="yourLP">
          <p>You have:</p>
          <div className="yourLPAmount">
            {Number.isNaN(parseInt(balance, 10)) ? '-.--' : parseFloat(fromWei(balance)).toFixed(2)}
            <span>{`${farmModel.token0Symbol}-${farmModel.token1Symbol} LP`}</span>
          </div>
        </div>

        <div className="reward">
          <p>Reward:</p>
          <div className="rewardAmount">
          {Number.isNaN(stream) || stream === 0 ? (
            <>-.--</>
          ) : (
            <>
              <span>
              {' ~'} {stream.toFixed(2)}
              </span>
              {' '}
              <a
                href={`https://blockscout.com/xdai/mainnet/address/${farmModel.rewardToken}/transactions`}
                target="_blank"
                rel="noreferrer"
              >
                {farmModel.rewardTokenSymbol} per WEEK
              </a>
            </>
          )}
          </div>
        </div>


        {currentFlowrate() > 0.99 ? (
          <>
            <div className="maxedOutWrap">
              <p>This farm reached it's maximum flowrate.

              {farmModel.token0Symbol === 'MIVA' && farmModel.token1Symbol === 'WXDAI' ? (
                <a className="button" href="https://niftyfair.io/gc/collection/0xb81cf21256af0c688d93f49534a0d375346de458/" target="_blank" title="Buy a Minerva Farming Position NFT for the MIVA:WXDAI HoneySwap Farm on niftyfair.io" rel="noreferrer">Buy a FP-NFT on NiftyFair</a>
              ) : ( <></> ) }

              {farmModel.token0Symbol === 'MIVA' && farmModel.token1Symbol === 'HNY' ? (
                <a className="button" href="https://niftyfair.io/gc/collection/0x58a524458e8a196b1f5fa2da477a6111a6076268/" target="_blank" title="Buy a Minerva Farming Position NFT for the MIVA:HNY HoneySwap Farm on niftyfair.io" rel="noreferrer">Buy a FP-NFT on NiftyFair</a>
              ) : ( <></> ) }

              {farmModel.token0Symbol === 'FRACTION' && farmModel.token1Symbol === 'MIVA' ? (
                <a className="button" href="https://niftyfair.io/gc/collection/0x491b39272b19b6c1fe4c79026c4641509912386f/" target="_blank" title="Buy a Minerva Farming Position NFT for the FRACTION:MIVA HoneySwap Farm on niftyfair.io" rel="noreferrer">Buy a FP-NFT on NiftyFair</a>
              ) : ( <></> ) }

              {farmModel.token0Symbol === 'MIVA' && farmModel.token1Symbol === 'SYMM' ? (
                <a className="button" href="https://niftyfair.io/gc/collection/0x0a7e3b2196021d4e13d91ee61b53df24674ca3c1/" target="_blank" title="Buy a Minerva Farming Position NFT for the MIVA:SYMM Symmetric Farm on niftyfair.io" rel="noreferrer">Buy a FP-NFT on NiftyFair</a>
              ) : ( <></> ) }

              {farmModel.token0Symbol === 'MIVA' && farmModel.token1Symbol === 'FRACTION' ? (
                <a className="button" href="https://niftyfair.io/gc/collection/0x8ccc4d685236615126f94ec9850869d9f60f7684/" target="_blank" title="Buy a Minerva Farming Position NFT for the MIVA:FRACTION Symmetric Farm on niftyfair.io" rel="noreferrer">Buy a FP-NFT on NiftyFair</a>
              ) : ( <></> ) }


              </p>
            </div>
            <div className="maxedOutBadge"></div>
          </>
        ) : (
          <div className="formWrap">
          <p>Add LP Tokens</p>
          <div className="amountField">
          <input type="number" min="0" placeholder="amount" value={stakeAmount} disabled={!web3 || loading}  onChange={(e) => changeStakeAmount(e.currentTarget.value)}/>
          </div>
          <div className="maxButton">
          <button type="button" disabled={!web3 || loading} onClick={() => changeStakeAmount(fromWei(balance))}>ALL</button>
          </div>
          <div className="excButton">
          <button
          type="button"
          disabled={!web3 || (approved && (Number.isNaN(parseFloat(stakeAmount)) || parseFloat(stakeAmount) <= 0))
          || stakingGreaterThanBalance()
          || loading}
          onClick={approved ? stake : approve}
          >
        {approved ? 'Farm' : 'Approve'}
          </button>
          </div>
          </div>
        )}

        </div>
        <div className="clear"></div>
        <div className="flowRateWrap">
          <h3>Maximum flowrate reached:</h3>
          <div className="flowRate">
            <div className="flowRateBar" style={{width: `${currentFlowrate() > 0.99 ? 100 : currentFlowrate() * 100}%`}}>
              <div className="flowRateAnim">
                <div className="flowRateAnimOverlay">{`${currentFlowrate() > 0.99 ? 100 : (currentFlowrate() * 100).toFixed(0)}%`}</div>
              </div>
            </div>
          </div>
        </div>
        {streamTxHash && streamLogId && (
          <div className="superfluidStreamWrap">
            <a className="superfluidStream" href={`https://app.superfluid.finance/streams/gnosis/${streamTxHash}/${streamLogId}`} target="_blank" rel="noreferrer">
              Check your Reward Stream on <span>SuperFluid</span>
            </a>
          </div>
        )}

      </div>
    </div>
  );
}

export default Farm;
