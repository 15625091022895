import {
  useParams,
  withRouter,
  RouteComponentProps
} from "react-router-dom";
import FarmModel from "./model/FarmModel";
import {useEffect, useState} from "react";
import Web3 from "web3";
import ERC721Abi from "./abi/ERC721.json";
import {AbiItem, isAddress} from "web3-utils";
import BN from "bn.js";
import IStreamingFarmAbi from "./abi/IStreamingFarm.json";
import {useWeb3React} from "@web3-react/core";
import PositionsDetailItem from "./PositionsDetailItem";

const rpc = process.env.REACT_APP_CHAIN_RPC_READ || process.env.REACT_APP_CHAIN_RPC || '';
const gasPrice = process.env.REACT_APP_GAS_PRICE || '';

interface RouterParams {
  address: string;
  id: string;
}

interface PositionDetailProps extends RouteComponentProps<any> {
  farms: FarmModel[];
  listings: any[];
}

function PositionDetail(props: PositionDetailProps): JSX.Element {
  const { address, id } = useParams<RouterParams>();
  const { farms, history, listings } = props;
  const { active, connector, account } = useWeb3React();
  const readWeb3 = new Web3(rpc);
  const [ loading, setLoading ] = useState<boolean>(true);
  const [ farmModel, setFarmModel ] = useState<FarmModel|undefined>(undefined);
  const [web3, setWeb3] = useState<Web3|null>(null);
  const [ wrongAddress, setWrongAddress ] = useState<boolean>(false);
  const [ wrongId, setWrongId ] = useState<boolean>(false);
  const [ owner, setOwner ] = useState<string>('');

  useEffect(() => {
    if (active) {
      connector?.getProvider().then((provider) => {
        // Instantiate web3.js
        const instance = new Web3(provider);
        setWeb3(instance);
      });
    } else {
      setWeb3(null);
    }
  }, [active, connector]);

  const loadPosition = async () => {
    if (!farms || farms.length === 0) {
      return;
    }
    setLoading(true);

    const farmAddr = farms.find((farm) => farm.farmNft.toLowerCase() === address.toLowerCase())?.farmAddr;
    if (farmAddr) {
      history.push(`/position/${farmAddr}/${id}`);
      setLoading(false);
      return;
    }

    const farmM = farms.find((farm) => farm.farmAddr.toLowerCase() === address.toLowerCase());
    if (!farmM) {
      setWrongAddress(true);
      setLoading(false);
      return;
    }
    setFarmModel(farmM);

    // todo: check if id exists
    const nftContract = new readWeb3.eth.Contract(ERC721Abi as AbiItem[], farmM.farmNft);// as ERC721;
    let o = undefined;
    try {
      o = await nftContract.methods.ownerOf(id).call();
    } catch (error) {
      console.log('no owner');
    }
    if (!o) {
      setWrongId(true);
      setLoading(false);
      return;
    }
    setOwner(o);
    setLoading(false);
  };

  useEffect(() => {
    loadPosition();
  }, [address, id, farms]);

  const unstake = async (farmAddr: string, id: number | string | BN) => {
    if (!web3) {
      alert('no web3.');
      return;
    }

    const farmContract = new web3.eth.Contract(IStreamingFarmAbi as AbiItem[], farmAddr);// as IStreamingFarm;
    await farmContract.methods.unstake(id).send({ from: account, gasPrice });

    await loadPosition();
  };

  const upgrade = async (farmAddr: string, id: number | string | BN) => {
    if (!web3) {
      alert('no web3.');
      return;
    }

    const farmContract = new web3.eth.Contract(IStreamingFarmAbi as AbiItem[], farmAddr);// as IStreamingFarm;
    await farmContract.methods.upgradeLevel(id).send({ from: account, gasPrice });

    await loadPosition();
  };

  const send = async (farmNft: string, id: number | string | BN, to: string) => {
    if (!web3) {
      alert('no web3.');
      return;
    }
    if (!isAddress(to)) {
      alert('address not valid.');
      return;
    }

    const nftContract = new web3.eth.Contract(ERC721Abi as AbiItem[], farmNft);
    await nftContract.methods.transferFrom(account, to, id).send({ from: account, gasPrice });

    await loadPosition();
  };

  const isOwner = (): boolean => {
    return account !== '' && account?.toLowerCase() === owner.toLowerCase();
  };

  return (
    <div className="mainApp">
      {loading ? (
        <p>
          loading
        </p>
      ) : wrongAddress ? (
        <p>
          Wrong address
        </p>
      ) : wrongId ? (
        <p>
          Wrong id
        </p>
      ) : (
        <>
          {!farmModel ? (
            <p>loading error</p>
          ) : (
            <PositionsDetailItem
              farmModel={farmModel}
              id={parseInt(id, 10)}
              listing={listings.filter((l) => {
                // weird string comparison for ids, but somehow this works instead of normal number compare.
                return l.tokenAddress.toLowerCase() === farmModel.farmNft.toLowerCase() && l.tokenId === id;
              })[0]}
              owner={owner}
              isOwner={isOwner}
              unstake={unstake}
              upgrade={upgrade}
              send={send}
            />
          )}
        </>
      )}
    </div>
  );
}
export default withRouter(PositionDetail);