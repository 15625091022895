import FarmModel from './FarmModel';

export default class NftModel {
  farmModel: FarmModel;

  nftAddr: string;

  id: number;

  constructor(farmModel: FarmModel, nftAddr: string, id: number) {
    this.farmModel = farmModel;
    this.nftAddr = nftAddr;
    this.id = id;
  }
}
