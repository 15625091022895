import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import {AbiItem, isAddress, toWei} from 'web3-utils';
import BN from 'bn.js';
import IERC20Abi from './abi/IERC20.json';
import { IERC20 } from './contract-types/IERC20';
import ERC721Abi from './abi/ERC721.json';
import IListingGettersAbi from './abi/IListingGetters.json';
import { ERC721 } from './contract-types/ERC721';
import IStreamingFarmAbi from './abi/IStreamingFarm.json';
import { IStreamingFarm } from './contract-types/IStreamingFarm';
import Nft from './Nft';
import Farm from './Farm';
import FarmModel from './model/FarmModel';
import NftModel from './model/NftModel';
import {range} from "./App";
import loadingAnimationSVG from './images/loading.svg';

const gasPrice = process.env.REACT_APP_GAS_PRICE || '';

interface HomeProps {
  farms: FarmModel[];
  listings: any[];
}

function Home(props: HomeProps) {
  const { farms, listings } = props;
  const { active, connector, account } = useWeb3React();
  const [loading, setLoading] = useState<boolean>(true);
  const [loading2, setLoading2] = useState<boolean>(true);
  const [web3, setWeb3] = useState<Web3|null>(null);
  const [nfts, setNfts] = useState<NftModel[]>([]);
  const [openExplanations, setOpenExplanations] = useState<boolean>(false);

  useEffect(() => {
    if (active) {
      connector?.getProvider().then((provider) => {
        // Instantiate web3.js
        const instance = new Web3(provider);
        setWeb3(instance);
      });
    } else {
      setWeb3(null);
    }
  }, [active, connector]);

  useEffect(() => {
    setNfts([]);
    checkNfts();
    if (farms.length > 0) {
      setLoading2(false);
    }
  }, [web3, account, farms]);

  const checkNfts = async () => {
    if (!web3 || !account) {
      return;
    }
    setLoading(true);
    setNfts([...(await Promise.all(farms.map(async (farm) => {
      const nftContract = new web3.eth.Contract(ERC721Abi as AbiItem[], farm.farmNft);// as ERC721;
      const balance: string = await nftContract.methods.balanceOf(account).call();

      const ids: number[] = await Promise.all(
        range(0, parseInt(balance, 10) + 1)
          .map(async (element, index) => nftContract.methods.tokenOfOwnerByIndex(account, index).call()),
      );
      return ids.map((id) => new NftModel(farm, farm.farmNft, id));
    }))).flat()]);
    setLoading(false);
  };

  const unstake = async (farmAddr: string, id: number | string | BN) => {
    if (!web3) {
      alert('no web3.');
      return;
    }

    const farmContract = new web3.eth.Contract(IStreamingFarmAbi as AbiItem[], farmAddr);// as IStreamingFarm;
    await farmContract.methods.unstake(id).send({ from: account, gasPrice });

    await checkNfts();
  };

  const upgrade = async (farmAddr: string, id: number | string | BN) => {
    if (!web3) {
      alert('no web3.');
      return;
    }

    const farmContract = new web3.eth.Contract(IStreamingFarmAbi as AbiItem[], farmAddr);// as IStreamingFarm;
    await farmContract.methods.upgradeLevel(id).send({ from: account, gasPrice });

    await checkNfts();
  };

  const send = async (farmNft: string, id: number | string | BN, to: string) => {
    if (!web3) {
      alert('no web3.');
      return;
    }
    if (!isAddress(to)) {
      alert('address not valid.');
      return;
    }

    const nftContract = new web3.eth.Contract(ERC721Abi as AbiItem[], farmNft);
    await nftContract.methods.transferFrom(account, to, id).send({ from: account, gasPrice });

    await checkNfts();
  };

  return (
    <div className="mainApp">
      <div className="flowers">
        { /*
        <div className="flower flower-1"></div>
        <div className="flower flower-2"></div>
        <div className="flower flower-3"></div>
        <div className="flower flower-4"></div>
        <div className="flower flower-5"></div>
        <div className="flower flower-6"></div>
        <div className="flower flower-7"></div>
        <div className="flower flower-8"></div>
        <div className="flower flower-9"></div>
        <div className="flower flower-10"></div> 
        */ }
      </div>
      <div className="mainAppInner">
        <div className="logo"></div>
        <div className="colSet">

          <div className="rightSide">


            <div className="farmWrapper">
              <div className="farmList">
                <h2>ACTIVE FARMS ON GnosisChain</h2>
                {loading2 ? (
                  <figure className="loadingAnimation">
                    <img src={loadingAnimationSVG} alt="loading farms..." />
                  </figure>
                ) :
                  farms.filter((f) => f.farmAddr === '0xa2eac044fe1e004cAaC4E8C4164a39F4Cc522b6f' || f.farmAddr === '0xFd989d6E3244cFb5470597E7B93E4430CC29EfE9').map((farmModel) => (
                    <Farm web3={web3} farmModel={farmModel} checkNfts={checkNfts} key={farmModel.farmAddr} />
                  ))
                }

              </div>




              <p className="betaNote">'MIVA Streaming Farms' is currently in beta. If an error occurs, refresh the page and try reconnecting. In case the error persits, get in contact with us on
              {' '}
              <a className="twitter" target="_blank" rel="noreferrer" href="https://twitter.com/MinervaWallet" title="Follow @MinervaWallet on Twitter">Twitter</a>,
              {' '}
              <a className="discord" target="_blank" rel="noreferrer" href="https://discord.gg/UzKcmMZNjm" title="Join the Minerva Discord-Server">Discord</a>
              {' '}
              or
              {' '}
              <a className="telegram" target="_blank" rel="noreferrer" href="https://t.me/MinervaWallet" title="Minerva Wallet Group on Telegram">Telegram</a>!
              </p>
            </div>

        </div>

        <div className="leftSide">
          <h2>CLOSED FARMS</h2>
          <div className="closedFarms">

            <div className="closedFarm gc-hs-MIVA-HNY">
              <h3>
                <a href="https://gnosis.blockscout.com/address/0x89Abea6823cfd903fB503A1DB17a7ce890A3232e" target="_blank" title="MIVA:HNY Farm for Honeyswap on GnosisChain">
                  MIVA:HNY Farm
                </a>
              </h3>
              <p className="closedFarmTimespan">Jun 2021 - Dec 2023</p>
              <div className="closedFarmInner">
                <p>
                  <span className="label">Duration</span>
                  <span className="value">2 years and 6 month</span>
                </p>
                <p>
                  <span className="label">Emmited</span>
                  <span className="value">336.670 MIVA</span>
                </p>
                <p>
                  <span className="label">Chain</span>
                  <a className="value" href="https://gnosischain.com" target="_blank" title="GnosisChain">GnosisChain</a>
                </p>
                <p>
                  <span className="label">DEX</span>
                  <a className="value" href="https://app.honeyswap.org" target="_blank" title="Honeyswap">Honeyswap</a>
                </p>
              </div>
              <div className="closedBadge"></div>
            </div>

            <div className="closedFarm gc-sy-MIVA-SYMM">
                <h3>
                  <a href="https://gnosis.blockscout.com/address/0x867e84EB2789c95eEF6d6991cC4bC6B48e1519b8" target="_blank" title="MIVA:SYMM Farm for Symmetric v1 on GnosisChain">
                    MIVA:SYMM Farm
                  </a>
                </h3>
                <p className="closedFarmTimespan">Oct 2021 - Dec 2023</p>
                <div className="closedFarmInner">
                  <p>
                    <span className="label">Duration</span>
                    <span className="value">2 years and 3 month</span>
                  </p>
                  <p>
                    <span className="label">Emmited</span>
                    <span className="value">1.110.962 MIVA</span>
                  </p>
                  <p>
                    <span className="label">Chain</span>
                    <a className="value" href="https://gnosischain.com" target="_blank" title="GnosisChain">GnosisChain</a>
                  </p>
                  <p>
                    <span className="label">DEX</span>
                    <a className="value" href="https://xdai-pools.symmetric.exchange/#/explore" target="_blank" title="Symmetric v1">Symmetric v1</a>
                  </p>
                </div>
                <div className="closedBadge"></div>
              </div>

            <div className="closedFarm gc-sy-MIVA-FRACTION">
                <h3>
                  <a href="https://gnosis.blockscout.com/address/0x16daae140FbC2F854Cf61af0512Bd8CD627d0B8e" target="_blank" title="MIVA:FRACTION Farm for Symmetric v1 on GnosisChain">
                    MIVA:FRACTION Farm
                  </a>
                </h3>
                <p className="closedFarmTimespan">Nov 2021 - Dec 2023</p>
                <div className="closedFarmInner">
                  <p>
                    <span className="label">Duration</span>
                    <span className="value">2 years and 1 month</span>
                  </p>
                  <p>
                    <span className="label">Emmited</span>
                    <span className="value">163.107 MIVA</span>
                  </p>
                  <p>
                    <span className="label">Chain</span>
                    <a className="value" href="https://gnosischain.com" target="_blank" title="GnosisChain">GnosisChain</a>
                  </p>
                  <p>
                    <span className="label">DEX</span>
                    <a className="value" href="https://xdai-pools.symmetric.exchange/#/explore" target="_blank" title="Symmetric v1">Symmetric v1</a>
                  </p>
                </div>
                <div className="closedBadge"></div>
            </div>

          </div>

          {/*
          <h2>How to</h2>
          <div className="explanations explanationFirst video">
            <iframe width="290" height="160" src="https://www.youtube-nocookie.com/embed/miJc-s6VoJc?si=YXwf5YLZ-dg1QmxE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            <iframe width="290" height="160" src="https://www.youtube-nocookie.com/embed/miJc-s6VoJc?si=YXwf5YLZ-dg1QmxE" title="YouTube video player"></iframe>
          </div>
          */}

          <h2>TL,DR</h2>
          <div className="explanations tldr">
            <h3>6 Simple steps</h3>
            <ol>
              <li>Get <a href="https://jumper.exchange/?toChain=100&toToken=0x0000000000000000000000000000000000000000" title="How to get xDai" target="_blank" rel="noreferrer">xDai</a> on GnosisChain</li>
              <li>
                Get <a href="https://jumper.exchange/?toChain=100&toToken=0x63e62989D9EB2d37dfDB1F93A22f063635b07d51" title="Get MIVA on Honeyswap" target="_blank" rel="noreferrer">MIVA</a> and/or <a href="https://jumper.exchange/?toChain=100&toToken=0x63e62989D9EB2d37dfDB1F93A22f063635b07d51" title="Get MIVA on Honeyswap" target="_blank" rel="noreferrer">FRACTION</a>
              </li>    
              <li>Provide liquidity in these DEX pools
                  {' '}
                  <a href="https://info.honeyswap.org/#/pair/0x19b8eb5ffc078a0b50274c08d955900bd0007e32" target="_blank" rel="noreferrer">MIVA:WXDAI</a> or&nbsp;
                  <a href="https://info.honeyswap.org/#/pair/0xec771f115177ef1227a48cc1e8c34b19cbcefa8b" target="_blank" rel="noreferrer">MIVA:FRACTION</a>
                  {' '}
              </li>
              <li>Place LP Token in the matching 'Minerva&nbsp;Streaming&nbsp;Farm'</li>
              <li>Receive a valuable, tradeable 'Minerva Farm Position' NFT</li>
              <li>Earn a continuous MIVA stream via SuperFluid</li>
            </ol>

            <h3>Note</h3>
            <ul>
              <li>Level-up your NFT for higher flow rates (up to 177.6% APR)</li>
              <li>The NFT is transferable - whoever holds the NFT, owns the provided liquidity AND gets the Farming Reward Stream.</li>
              <li>Want to exit? Np!<br />Just hit 'Stop farming' on the NFT <br />to get your LP-Token back.</li>
            </ul>

              <p><a href="https://mirror.xyz/minerva.eth/fjqAv7tyCKVyLoLdnE6GHf20tSXGgUa6aIb4WCunhCQ" className="button" target="_blank" rel="noreferrer">More about Streaming Farms</a></p>

          </div>

        </div>

        <div className="clear"></div>
      </div>

      <div className="sectionSeparator"></div>

        <div className="nftWrapper">
          <h2>Your Minerva Farm Positions</h2>
          <p>Once you added LP-Tokens to the farm, you will see here your Farming NFTs. Whoever holds the NFT, gets the farming reward stream. Don't miss out on leveling up to get the maximum possible flowrate.</p>

          {!account ? (<p className="conenctFirst">Connect to your account to see your Farming Positions.</p>) : (
            <div className="nft-list">
              {nfts.map((nft) => (
                <Nft
                  farmModel={nft.farmModel}
                  id={nft.id}
                  listing={listings.filter((l) => {
                    // weird string comparison for ids, but somehow this works instead of normal number compare.
                    return l.tokenAddress.toLowerCase() === nft.nftAddr.toLowerCase() && l.tokenId === nft.id.toString();
                  })[0]}
                  unstake={unstake}
                  upgrade={upgrade}
                  send={send}
                  key={`${nft.nftAddr}-${nft.id}`}
                />
              ))}
            </div>
          )}

        </div>

      <div className="sectionSeparator"></div>

      <div className="niftyFairBottom">
          <a className="mainLink" href="https://niftyfair.io/gnosis/collections/" target="_blank" title="All Minerva Farming Position NFTs on NiftyFair" rel="noreferrer">All Minerva Farming Position NFTs on NiftyFair</a>
      </div>

      <div className="niftyFairBottomCollections">
        <span>Individual Collections</span>
        <a className="collectionLink" href="https://niftyfair.io/gnosis/collection/0xb81cf21256af0c688d93f49534a0d375346de458/" target="_blank" title="Buy a Minerva Farming Position NFT for the MIVA:WXDAI HoneySwap Farm on niftyfair.io" rel="noreferrer">MIVA:WXDAI</a>
        <a className="collectionLink" href="https://niftyfair.io/gnosis/collection/0x58a524458e8a196b1f5fa2da477a6111a6076268/" target="_blank" title="Buy a Minerva Farming Position NFT for the MIVA:HNY HoneySwap Farm on niftyfair.io" rel="noreferrer">MIVA:HNY</a>
        <a className="collectionLink" href="https://niftyfair.io/gnosis/collection/0x491b39272b19b6c1fe4c79026c4641509912386f/" target="_blank" title="Buy a Minerva Farming Position NFT for the FRACTION:MIVA HoneySwap Farm on niftyfair.io" rel="noreferrer">FRACTION:MIVA</a>
        <a className="collectionLink" href="https://niftyfair.io/gnosis/collection/0x0a7e3b2196021d4e13d91ee61b53df24674ca3c1/" target="_blank" title="Buy a Minerva Farming Position NFT for the MIVA:SYMM Symmetric Farm on niftyfair.io" rel="noreferrer">MIVA:SYMM</a>
        <a className="collectionLink" href="https://niftyfair.io/gnosis/collection/0x8ccc4d685236615126f94ec9850869d9f60f7684/" target="_blank" title="Buy a Minerva Farming Position NFT for the MIVA:FRACTION Symmetric Farm on niftyfair.io" rel="noreferrer">MIVA:FRACTION</a>
      </div>

      { /*   
      <div className="sectionSeparator"></div>

        <div className="explanations">

          <h3>Step-by-step<span className="openArrow hide"></span></h3>
          <div className="mobileShowContainer show">
            <h4><strong>Honeyswap</strong></h4>
            <ol>
              <li><a href="https://docs.minerva.digital/miva-and-xdai-chain/how-to-get-xdai" title="How to get xDai" target="_blank" rel="noreferrer">Get xDai</a></li>
              <li><a href="https://app.honeyswap.org/#/swap?outputCurrency=0x63e62989D9EB2d37dfDB1F93A22f063635b07d51" title="Get MIVA on Honeyswap" target="_blank" rel="noreferrer">Get MIVA</a></li>
              <li><a href="https://app.honeyswap.org/#/pool" title="Provide Liquidity on Honeyswap" target="_blank" rel="noreferrer">Provide Liquidity on Honeyswap</a><br /> to get the UNI-V2-LP Token</li>
            </ol>

            <h4><strong>Symmetric</strong></h4>
            <ol>
              <li><a href="#####" title="How to get SYMM" target="_blank" rel="noreferrer">Get SYMM</a></li>
              <li><a href="https://xdai.symmetric.exchange/#/swap" title="Get MIVA on Symmetric" target="_blank" rel="noreferrer">Get MIVA</a></li>
              <li><a href="https://xdai-pools.symmetric.exchange/#/explore" title="Provide Liquidity on Symmetric 50:50 Pool" target="_blank" rel="noreferrer">Provide Liquidity on supported Symmetric's 50:50-Pools</a> to get the CPT Token.</li>
            </ol>

            <h4><strong>Then</strong></h4>
            <ol>
              <li>Connect your Account on the farm</li>
              <li>Approve the Token</li>
              <li>Select how much of that LP token you want to put into the Farm</li>
              <li>Add the Token to the Farm</li>
              <li>Start getting MIVA via the Farming Stream! :D</li>
            </ol>
            <p>That opens up the Farming Stream and you'll get the Farming NFT. Whoever holds the NFT, gets the Farming Stream.</p>
            <p>If you have multiple FP-NTFs from one farm, your stream gets bundled into one stream.</p>
            <p>Check your current farming level! If you could be on a higher level, the graphic on the NFT pulses orange. Upgrade to higher levels whenever possible.</p>
          </div>
        </div>
        */ }



      <div className="sectionSeparator"></div>


        <div className="explanations">
          <h3 onClick={() => setOpenExplanations(!openExplanations)}>
            Useful Links <span className={openExplanations ? 'openArrow hide' : 'openArrow'}></span>
          </h3>
          <div className={openExplanations ? 'mobileShowContainer show' : 'mobileShowContainer'}>
            <h4>Contracts</h4>
            <p className="usefulLinks">
              <a href="https://blockscout.com/xdai/mainnet/address/0x19b8eb5ffc078a0b50274c08d955900bd0007e32/transactions" target="_blank" rel="noreferrer">MIVA:XDAI UNI-V2-LP-Token</a>
              <a href="https://blockscout.com/xdai/mainnet/address/0xe7deD59a062823C3F3AC34D3fD4542E6d61BA010/transactions" target="_blank" rel="noreferrer">MIVA:HNY UNI-V2-LP-Token</a>
              <a href="https://blockscout.com/xdai/mainnet/address/0xEc771F115177Ef1227a48Cc1E8c34B19CbCeFA8b/transactions" target="_blank" rel="noreferrer">FRACTION:MIVA UNI-V2-LP-Token</a>
              <a href="https://blockscout.com/xdai/mainnet/address/0x79670b0Cb738A0bD826BC7709Bc363c6B554690b/transactions" target="_blank" rel="noreferrer">MIVA:SYMM 50:50 CPT-Token</a>
              <a href="https://blockscout.com/xdai/mainnet/address/0x93B599b54af63518d1DCA6a116f323f33888453c/transactions" target="_blank" rel="noreferrer">MIVA:FRACTION 50:50 CPT-Token</a>

              <a href="https://blockscout.com/xdai/mainnet/address/0xa2eac044fe1e004cAaC4E8C4164a39F4Cc522b6f/transactions" target="_blank" rel="noreferrer">MIVA:XDAI Farm</a>
              <a href="https://blockscout.com/xdai/mainnet/address/0x89Abea6823cfd903fB503A1DB17a7ce890A3232e/transactions" target="_blank" rel="noreferrer">MIVA:HNY Farm</a>
              <a href="https://blockscout.com/xdai/mainnet/address/0xFd989d6E3244cFb5470597E7B93E4430CC29EfE9/transactions" target="_blank" rel="noreferrer">MIVA:FRACTION Farm</a>
              <a href="https://blockscout.com/xdai/mainnet/address/0x867e84EB2789c95eEF6d6991cC4bC6B48e1519b8/transactions" target="_blank" rel="noreferrer">MIVA:SYMM Farm</a>
              <a href="https://blockscout.com/xdai/mainnet/address/0x16daae140FbC2F854Cf61af0512Bd8CD627d0B8e/transactions" target="_blank" rel="noreferrer">MIVA:FRACTION Farm</a>

              <a href="https://blockscout.com/xdai/mainnet/tokens/0xb81cf21256af0c688d93f49534a0d375346de458" target="_blank" rel="noreferrer">MIVA:XDAI Farm-NFT Contract</a>
              <a href="https://blockscout.com/xdai/mainnet/tokens/0x58a524458e8a196b1f5fa2da477a6111a6076268" target="_blank" rel="noreferrer">MIVA:HNY Farm-NFT Contract</a>
              <a href="https://blockscout.com/xdai/mainnet/tokens/0x491b39272b19b6c1fe4c79026c4641509912386f" target="_blank" rel="noreferrer">FRACTION:MIVA Farm-NFT Contract</a>
              <a href="https://blockscout.com/xdai/mainnet/tokens/0x867e84EB2789c95eEF6d6991cC4bC6B48e1519b8" target="_blank" rel="noreferrer">MIVA:SYMM Farm-NFT Contract</a>

              <a href="https://blockscout.com/xdai/mainnet/address/0x63e62989D9EB2d37dfDB1F93A22f063635b07d51/transactions" target="_blank" rel="noreferrer">MIVA Token</a>
              <a href="https://blockscout.com/xdai/mainnet/address/0x2bF2ba13735160624a0fEaE98f6aC8F70885eA61/transactions" target="_blank" rel="noreferrer">FRACTION Token</a>
            </p>
            <h4>DEX Pools</h4>
            <p className="usefulLinks">
              <a href="https://info.honeyswap.org/#/pair/0x19b8eb5ffc078a0b50274c08d955900bd0007e32" title="MIVA:WXDAI Pool on Honeyswap" rel="noreferrer" target="_blank">MIVA:xDAI Pool</a>
              <a href="https://info.honeyswap.org/#/pair/0xe7ded59a062823c3f3ac34d3fd4542e6d61ba010" title="MIVA:HNY Pool on Honeyswap" rel="noreferrer" target="_blank">MIVA:HNY Pool</a>
              <a href="https://info.honeyswap.org/#/pair/0xec771f115177ef1227a48cc1e8c34b19cbcefa8b" title="MIVA:FRACTION Pool on Honeyswap" rel="noreferrer" target="_blank">MIVA:FRACTION Pool</a>
              <a href="https://xdai-pools.symmetric.exchange/#/pool/0x79670b0cb738a0bd826bc7709bc363c6b554690b" title="MIVA:SYMM Pool on Symmetric" rel="noreferrer" target="_blank">MIVA:SYMM Pool</a>
              <a href="https://xdai-pools.symmetric.exchange/#/pool/0x93b599b54af63518d1dca6a116f323f33888453c" title="MIVA:FRACTION Pool on Symmetric" rel="noreferrer" target="_blank">MIVA:FRACTION Pool</a>
            </p>
            <h4>Misc</h4>
            <p className="usefulLinks">
              <a href="https://www.coingecko.com/en/coins/minerva-wallet" title="MIVA Token on Coingecko" rel="noreferrer" target="_blank">Coingecko: MIVA Token</a>
              <a href="https://coinmarketcap.com/currencies/minerva-wallet/" title="MIVA Token on Coingecko" rel="noreferrer" target="_blank">Coinmarketcap: MIVA Token</a>
              <a href="https://www.coingecko.com/en/coins/fraction" title="FRACTION Token on Coingecko" rel="noreferrer" target="_blank">Coingecko: FRACTION Token</a>
              <a href="https://minerva.digital" title="Minerva Wallet" rel="noreferrer" target="_blank">minerva.digital</a>
              <a href="https://fraction.fyi" title="Own a FRACTION" rel="noreferrer" target="_blank">fraction.fyi</a>
            </p>
          </div>

        </div>



      </div>
    </div>
  );
}

export default Home;
