import React, { useEffect, useState } from 'react';
import {AbiItem, fromWei, isAddress} from 'web3-utils';
import Web3 from 'web3';
// eslint-disable-next-line
import BN from 'bn.js';
import ERC721Abi from './abi/ERC721.json';
import IStreamingFarmAbi from './abi/IStreamingFarm.json';
import IERC20Abi from './abi/IERC20.json';
import IUniswapV2PairAbi from './abi/IUniswapV2Pair.json';
import FarmModel from './model/FarmModel';

const rpc = process.env.REACT_APP_CHAIN_RPC_READ || process.env.REACT_APP_CHAIN_RPC || '';

// see: https://stackoverflow.com/a/66390028/782920
const units: {unit: Intl.RelativeTimeFormatUnit; ms: number}[] = [
  { unit: 'year', ms: 31536000000 },
  { unit: 'month', ms: 2628000000 },
  { unit: 'day', ms: 86400000 },
  { unit: 'hour', ms: 3600000 },
  { unit: 'minute', ms: 60000 },
  { unit: 'second', ms: 1000 },
];
const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

/**
 * Get language-sensitive relative time message from Dates.
 * @param relative  - the relative dateTime, generally is in the past or future
 * @param pivot     - the dateTime of reference, generally is the current time
 */
export function relativeTimeFromDates(relative: Date | null, pivot: Date = new Date()): string {
  if (!relative) return '';
  const elapsed = relative.getTime() - pivot.getTime();
  return relativeTimeFromElapsed(elapsed);
}

const printError = (e: any): void => {
  console.error(e);
  if (e instanceof Object && e.hasOwnProperty('message')) {
    alert(`Error: ${e.message}`);
  } else {
    alert(e);
  }
};

/**
 * Get language-sensitive relative time message from elapsed time.
 * @param elapsed   - the elapsed time in milliseconds
 */
export function relativeTimeFromElapsed(elapsed: number): string {
  // eslint-disable-next-line no-restricted-syntax
  for (const { unit, ms } of units) {
    if (Math.abs(elapsed) > ms || unit === 'second') {
      return rtf.format(Math.round(elapsed / ms), unit);
    }
  }
  return '';
}

interface PositionsDetailItemProps {
  farmModel: FarmModel;
  id: number;
  listing: any;
  owner: string;
  isOwner: () => boolean;
  unstake: (farmAddr: string, id: number | string | BN) => void;
  upgrade: (farmAddr: string, id: number | string | BN) => void;
  send: (farmNft: string, id: number | string | BN, to: string) => void;
}

function PositionsDetailItem(props: PositionsDetailItemProps): JSX.Element {
  const {
    farmModel, id, listing, unstake, upgrade, send, owner, isOwner,
  } = props;
  const readWeb3 = new Web3(rpc);
  const [upgradable, setUpgradable] = useState<boolean>(false);
  const [level, setLevel] = useState<string>('');
  const [availableLevel, setAvailableLevel] = useState<string>('');
  const [stakeAmount, setStakeAmount] = useState<string>('');
  const [upgradeTs, setUpgradeTs] = useState<string>('');
  const [lpValue, setLpValue] = useState<number>(0);
  const [stream, setStream] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sendAddr, setSendAddr] = useState<string>('');
  const [tokenJson, setTokenJson] = useState<any>({});

  useEffect(() => {
    fetchDetails();
  }, [id]);

  const fetchDetails = async () => {
    setLoading(true);
    const nftContract = new readWeb3.eth.Contract(ERC721Abi as AbiItem[], farmModel.farmNft);// as ERC721;
    const tokenUri = await nftContract.methods.tokenURI(id).call();
    try {
      const tokenUriResp = await fetch(tokenUri);
      setTokenJson(await tokenUriResp.json());
    } catch (e) {
      console.error('failed fetching tokenUri json.')
    }

    const farmContract = new readWeb3.eth.Contract(IStreamingFarmAbi as AbiItem[], farmModel.farmAddr);
    const canUpgrade = await farmContract.methods.canUpgradeLevel(id).call();
    // console.log('canUpgrade', id, canUpgrade);
    setUpgradable(canUpgrade);

    const info = await farmContract.methods.getNFTInfo(id).call();
    // console.log('info', id, info);

    setLevel(info.setLevel);
    setAvailableLevel(info.availableLevel);
    setStakeAmount(info.stakeAmount);
    setUpgradeTs(info.nextLevelTimestamp);

    const token0Contract = new readWeb3.eth.Contract(IERC20Abi as AbiItem[], farmModel.token0);
    const token1Contract = new readWeb3.eth.Contract(IERC20Abi as AbiItem[], farmModel.token1);
    const xdaiContract = new readWeb3.eth.Contract(IERC20Abi as AbiItem[],
      '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d');
    const token0Balance = await token0Contract.methods.balanceOf(farmModel.stakingToken).call();
    const token1Balance = await token1Contract.methods.balanceOf(farmModel.stakingToken).call();

    const stakeTokenContract = new readWeb3.eth.Contract(IUniswapV2PairAbi as AbiItem[], farmModel.stakingToken);
    const stakeTokenSupply = await stakeTokenContract.methods.totalSupply().call();

    const share = parseInt(info.stakeAmount, 10) / parseInt(stakeTokenSupply, 10);
    const vInToken0 = 2 * parseFloat(fromWei(token0Balance)) * share;
    const vInToken1 = 2 * parseFloat(fromWei(token1Balance)) * share;

    // todo: make this more generic!
    if (farmModel.token0Symbol === 'WXDAI') {
      setLpValue(vInToken0);
    } else if (farmModel.token1Symbol === 'WXDAI') {
      setLpValue(vInToken1);
    } else if (farmModel.token0Symbol === 'MIVA') {
      const mivaBalance = await token0Contract.methods.balanceOf('0x19b8eb5ffc078a0b50274c08d955900bd0007e32').call();
      const xdaiBalance = await xdaiContract.methods.balanceOf('0x19b8eb5ffc078a0b50274c08d955900bd0007e32').call();
      const mivaPrice = (parseInt(xdaiBalance, 10) / parseInt(mivaBalance, 10));
      setLpValue(vInToken0 * mivaPrice);
    } else if (farmModel.token1Symbol === 'MIVA') {
      const mivaBalance = await token1Contract.methods.balanceOf('0x19b8eb5ffc078a0b50274c08d955900bd0007e32').call();
      const xdaiBalance = await xdaiContract.methods.balanceOf('0x19b8eb5ffc078a0b50274c08d955900bd0007e32').call();
      const mivaPrice = (parseInt(xdaiBalance, 10) / parseInt(mivaBalance, 10));
      setLpValue(vInToken1 * mivaPrice);
    }

    // interest per week of reference value
    const interestPerWeek = parseInt(farmModel.rewardSchedule[info.setLevel - 1][1], 10) / 1000000;
    setStream(parseFloat(fromWei(info.referenceValue)) * interestPerWeek);
    setLoading(false);
  };

  return (
    <div className="nft">

      <div>
        <p className="id">#{id}</p>
        <figure>
          <img
            className=""
            src={tokenJson?.image}
            alt={`${farmModel.token0Symbol}:${farmModel.token1Symbol} LvL ${level} Farming Token NFT`}
          />
        </figure>

        <div className="details">

          <div className="item hide">
            <div className="label">Pool</div>
            <div className="info">
              <a href={`https://blockscout.com/xdai/mainnet/address/${farmModel.stakingToken}/transactions`}>
              {`${farmModel.token0Symbol}:${farmModel.token1Symbol}`}
              </a>
            </div>
          </div>

          <div className="item hide">
            <div className="label">Level:</div>
            <div className="info">{level}</div>
          </div>

          <div className="item hide">
            <div className="label">Possible:</div>
            <div className="info">{availableLevel}</div>
          </div>

          <div className="item">
            <div className="label">LP placed</div>
            <div className="info">{fromWei(stakeAmount)}</div>
          </div>

          <div className="item">
            <div className="label">Value</div>
            <div className="info">{lpValue < 0.01 ? '< 0.01' : lpValue.toFixed(2)} $</div>
          </div>

          <div className="item">
            <div className="label">Next Level</div>
            <div className="info">
              {upgradeTs === '0' ? 'max. level reached'
                : upgradeTs === '' || relativeTimeFromDates(new Date(parseInt(upgradeTs, 10) * 1000)) } to Level&nbsp;{parseInt(availableLevel)+1}
            </div>
          </div>

          <div className="item">
            <div className="label">Reward Stream</div>
            <div className="info">
            {stream < 0.01 ? '< 0.01' : stream.toFixed(2)}
            &nbsp;{farmModel.rewardTokenSymbol}
            /week
            </div>
          </div>

          <div className="item">
            <div className="label">owner</div>
            <div className="info">
              <a href={`https://blockscout.com/xdai/mainnet/address/${owner}/transactions`} target="_blank" rel="noreferrer">
                {owner}
              </a>
            </div>
          </div>

          <div className="item lastItem">
            <div className="label">Trade it on:</div>
            <div className="info">
              {listing && `${parseFloat(fromWei(listing.price)).toFixed(2)} $ on `}
              <a className="eporioNFT" target="_blank" title="View this NFT on the Eporio Marketplace" href={`https://epor.io/tokens/${farmModel.farmNft.toLowerCase()}/${id}`} rel="noreferrer">Eporio</a>
            </div>
          </div>

        </div>


        <div className="nftButtonWrapper">

          <input type="text" className="sendNFT" placeholder="enter xDai address" onChange={(e) => setSendAddr(e.currentTarget.value)} />
          <button
            type="button"
            className="sendNFTButton"
            disabled={!isAddress(sendAddr) || !isOwner() || loading}
            onClick={async () => {
            setLoading(true);
            try {
              await send(farmModel.farmNft, id, sendAddr);
            } catch (e: any) {
              printError(e);
            } finally {
              setLoading(false);
            }
          }}>
            Transfer NFT
          </button>

        {' '}

        <button className="levelup" type="button" disabled={!upgradable || !isOwner() || loading} onClick={async () => {
          setLoading(true);
          try {
            await upgrade(farmModel.farmAddr, id);
          } catch (e: any) {
            printError(e);
          } finally {
            setLoading(false);
          }
        }}>
          Level-up
        </button>
        {' '}
        <button className="stopfarming" type="button" disabled={!isOwner() || loading} onClick={async () => {
          setLoading(true);
          try {
            await unstake(farmModel.farmAddr, id);
          } catch (e: any) {
            printError(e);
          } finally {
            setLoading(false);
          }
        }}>
          Stop Farming
        </button>
        <div className="clear"></div>
        </div>

      </div>
    </div>
  );
}

export default PositionsDetailItem;
