import React, {useEffect, useState} from 'react';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { UserRejectedRequestError } from '@web3-react/walletconnect-connector'

// this rpc is explicitly the non-read rpc.
const rpc = process.env.REACT_APP_CHAIN_RPC || '';
const connChainId = parseInt(process.env.REACT_APP_CHAIN_ID || '100', 10);
const bridge = process.env.REACT_APP_WC_BRIDGE || 'https://walletconnect-relay.minerva.digital';

interface ConnectProps {
  children: React.ReactNode
}

// eslint-disable-next-line react/prop-types
const Connect: React.FC<ConnectProps> = ({ children }) => {
  const {
    active, activate, deactivate, chainId, account, connector,
  } = useWeb3React();
  const [connectorType, setConnectorType] = useState<string>('');

  const onError = (err: any) => {
    console.error(err);
  };

  const activateInjected = async () => {
    addXdai();
    const injectedConnector = new InjectedConnector({ supportedChainIds: [connChainId] });
    await activate(injectedConnector, onError, true).catch((err) => {
      console.error(err);
      // alert(err);
    });
    setConnectorType('injected');
  };

  const activateWc = async (clickDeepLink = false) => {
    const walletconnectConnector = new WalletConnectConnector({ rpc: { [connChainId]: rpc }, bridge, chainId: connChainId });
    await activate(walletconnectConnector, (err: any) => {
      if (err.name === 'UserRejectedRequestError') {
        console.log('UserRejectedRequestError');
      } else {
        console.error(err.name, err.message, err);
      }
    }, false);
    // chrome needs a click otherwise it will be detected as popup?
    //document.getElementById('walletconnect-connect-button-Connect')?.click();
    setConnectorType('walletconnect');
  };

  const handleNetworkChanged = (networkId: string | number) => {
    console.log("Handling 'networkChanged' event with payload", networkId);
    // todo: fix?
    activateInjected();
  };

  const { ethereum } = window as any;
  if (ethereum && ethereum.on) {
    ethereum.on('chainChanged', handleNetworkChanged);
  }

  const addXdai = () => {
    // todo: add to env
    return (window as any)?.ethereum?.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x64',
          chainName: 'Gnosis Chain',
          rpcUrls: ['https://rpc.gnosischain.com'],
          nativeCurrency: {
            name: 'Gnosis Chain xDai',
            symbol: 'xDai',
            decimals: 18,
          },
          blockExplorerUrls: ['https://blockscout.com/xdai/mainnet'],
        },
      ],
      id: 1,
    }, console.log);
  };

  useEffect(() => {
    if (window.location.hash && window.location.hash === '#connect') {
      activateWc(true);
    }
  }, []);

  return (
    <>
      <header className="connect-header">
        {
          active ? (
            <div>
            <div className="label">Click to disconnect</div>
            <button
              type="button"
              className={connectorType === 'injected' ? 'connectWeb3' : 'connectWC'}
              onClick={() => {
                if (connectorType === 'walletconnect') {
                  (connector as WalletConnectConnector)?.close();
                }
                deactivate();
              }}
            >
              {account}
            </button>
            </div>
          ) : (
            <div>
              <div className="label">Connect your account</div>
              <button
                type="button"
                className="connectWeb3"
                onClick={() => activateInjected()}
                disabled={!(window as any)?.ethereum}
              >
                Metamask & Co (Web3)
              </button>
              <button type="button" className="connectWC"  onClick={() => activateWc()}>
                WalletConnect
              </button>
            </div>
          )
        }
      </header>
      <>{children}</>
    </>
  );
};

export default Connect;
