import React, {useEffect, useState} from 'react';
import {AbiItem} from 'web3-utils';
import Web3 from 'web3';
import FarmModel from './model/FarmModel';
import ERC721Abi from './abi/ERC721.json';
import NftModel from "./model/NftModel";
import PositionsListItem from "./PositionsListItem";
import {range} from "./App";

const rpc = process.env.REACT_APP_CHAIN_RPC_READ || process.env.REACT_APP_CHAIN_RPC || '';

interface PositionsListProps {
  farms: FarmModel[];
  listings: any[];
}

function PositionsList(props: PositionsListProps): JSX.Element {
  const { farms, listings } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [nfts, setNfts] = useState<NftModel[]>([]);
  const readWeb3 = new Web3(rpc);

  useEffect(() => {
    loadPositions();
  }, [farms]);

  const loadPositions = async () => {
    setLoading(true);
    setNfts([...(await Promise.all(farms.map(async (farm) => {
      const nftContract = new readWeb3.eth.Contract(ERC721Abi as AbiItem[], farm.farmNft);// as ERC721;

      const totalSupply: string = await nftContract.methods.totalSupply().call();

      const ids: number[] = await Promise.all(
        range(0, parseInt(totalSupply, 10) + 1)
          .map(async (element, index) => nftContract.methods.tokenByIndex(index).call()),
      );
      return ids.map((id) => new NftModel(farm, farm.farmNft, id));
    }))).flat()]);
    setLoading(false);
  };

  return (
    <div className="mainApp">
      list
      {
        loading ? (
          <p>loading...</p>
        ) : (
          <div>
            {nfts.map((nft) => (
              <PositionsListItem
                farmModel={nft.farmModel}
                id={nft.id}
                listing={listings.filter((l) => {
                  // weird string comparison for ids, but somehow this works instead of normal number compare.
                  return l.tokenAddress.toLowerCase() === nft.nftAddr.toLowerCase() && l.tokenId === nft.id.toString();
                })[0]}
                key={`${nft.nftAddr}-${nft.id}`}
              />
            ))}
          </div>
        )
      }
    </div>
  );
}

export default PositionsList;
