import { AbiItem } from 'web3-utils';
import Web3 from 'web3';
import IStreamingFarmAbi from '../abi/IStreamingFarm.json';
import IERC20Abi from '../abi/IERC20.json';
import IUniswapV2PairAbi from '../abi/IUniswapV2Pair.json';
import ERC721Abi from '../abi/ERC721.json';
import BPool from '../abi/BPool.json';

export default class FarmModel {
  farmAddr: string;

  stakingToken: string;

  farmNft: string;

  rewardToken: string;

  rewardTokenSymbol: string;

  token0: string;

  token1: string;

  token0Symbol: string;

  token1Symbol: string;

  rewardSchedule: any;

  nftSupply: string;

  farmLpBalance: string;

  remainingFlowrate: string;

  maxFlowrate: string;

  factory: string;

  constructor(farmAddr: string, stakingToken: string, farmNft: string, rewardToken: string, rewardTokenSymbol: string,
    token0: string, token1: string, token0Symbol: string, token1Symbol: string, rewardSchedule: any, nftSupply: string,
    farmLpBalance: string, remainingFlowrate: string, maxFlowrate: string, factory: string) {
    this.farmAddr = farmAddr;
    this.stakingToken = stakingToken;
    this.farmNft = farmNft;
    this.rewardToken = rewardToken;
    this.rewardTokenSymbol = rewardTokenSymbol;
    this.token0 = token0;
    this.token1 = token1;
    this.token0Symbol = token0Symbol;
    this.token1Symbol = token1Symbol;
    this.rewardSchedule = rewardSchedule;
    this.nftSupply = nftSupply;
    this.farmLpBalance = farmLpBalance;
    this.remainingFlowrate = remainingFlowrate;
    this.maxFlowrate = maxFlowrate;
    this.factory = factory;
  }

  public static async create(farmAddr: string): Promise<FarmModel> {
    const rpc = process.env.REACT_APP_CHAIN_RPC_READ || process.env.REACT_APP_CHAIN_RPC || '';
    const readWeb3 = new Web3(rpc);

    const farmContract = new readWeb3.eth.Contract(IStreamingFarmAbi as AbiItem[], farmAddr);// as IStreamingFarm;
    const stakingToken = await farmContract.methods.stakingToken().call();
    const farmNft = await farmContract.methods.farmNFT().call();

    const farmNftContract = new readWeb3.eth.Contract(ERC721Abi as AbiItem[], farmNft);
    const nftSupply = await farmNftContract.methods.totalSupply().call();

    const rewardToken = await farmContract.methods.rewardToken().call();
    const rewardSchedule = await farmContract.methods.rewardSchedule().call();
    // const apyLevel1 = (parseInt(rewardSchedule[0][1], 10) / 1000000 / 7) * 365;

    const remainingFlowrate = await farmContract.methods.remainingAvailableFlowrate().call();
    const maxFlowrate = await farmContract.methods.maxAggregateFlowrate().call();

    const rewardTokenContract = new readWeb3.eth.Contract(IERC20Abi as AbiItem[], rewardToken);
    const rewardTokenSymbol = await rewardTokenContract.methods.symbol().call();

    let token0 = '';
    let token1 = '';
    let factory = '';

    try {
      // try uniswap v2
      const lpContract = new readWeb3.eth.Contract(IUniswapV2PairAbi as AbiItem[], stakingToken);
      token0 = await lpContract.methods.token0().call();
      token1 = await lpContract.methods.token1().call();
      factory = await lpContract.methods.factory().call();
    } catch (e) {
      // try balancer v1
      const lpContract = new readWeb3.eth.Contract(BPool.abi as AbiItem[], stakingToken);
      const numTokens = await lpContract.methods.getNumTokens().call();
      if (numTokens > 2) {
        console.error('Balancer Pool', stakingToken, 'has more than 2 tokens');
      }
      const tokens = await lpContract.methods.getCurrentTokens().call();

      token0 = tokens[0];
      token1 = tokens[1];

      factory = await lpContract.methods.getController().call(); // is this the factory of balancer?
    }

    const lpContractErc20 = new readWeb3.eth.Contract(IERC20Abi as AbiItem[], stakingToken);
    const farmLpBalance = await lpContractErc20.methods.balanceOf(farmAddr).call();

    const token0Contract = new readWeb3.eth.Contract(IERC20Abi as AbiItem[], token0);
    const token0Symbol = await token0Contract.methods.symbol().call();

    const token1Contract = new readWeb3.eth.Contract(IERC20Abi as AbiItem[], token1);
    const token1Symbol = await token1Contract.methods.symbol().call();

    return new FarmModel(farmAddr, stakingToken, farmNft, rewardToken, rewardTokenSymbol, token0, token1,
      token0Symbol, token1Symbol, rewardSchedule, nftSupply, farmLpBalance, remainingFlowrate, maxFlowrate, factory);
  }
}
