import React, {useEffect, useState} from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import './App.css';
import Connect from './Connect';
import Home from './Home';
import PositionsList from "./PositionsList";
import PositionDetail from "./PositionDetail";
import FarmModel from "./model/FarmModel";
import IListingGettersAbi from "./abi/IListingGetters.json";
import {AbiItem} from "web3-utils";
import Web3 from "web3";

// see: https://stackoverflow.com/a/19506234/782920
export function range(start: number, end: number): number[] {
  return start === end ? [] : Array.apply(0, Array(end - 1))
    .map((element, index) => index + start);
}

function getLibrary(provider: any): Web3Provider {
  provider.transactionConfirmationBlocks = 1;
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

const rpc = process.env.REACT_APP_CHAIN_RPC_READ || process.env.REACT_APP_CHAIN_RPC || '';
const farmAddresses: string[] = (process.env.REACT_APP_FARM_ADDRESSES || '').split(',');

// see: https://codesandbox.io/s/web3-react-ethersjs-web3js-example-wgg13
function App(): JSX.Element {
  const readWeb3 = new Web3(rpc);
  const [loading, setLoading] = useState<boolean>(true);
  const [farms, setFarms] = useState<FarmModel[]>([]);
  const [listings, setListings] = useState<any[]>([]);

  const loadFarms = async () => {
    setFarms(await Promise.all(farmAddresses.map((addr) => FarmModel.create(addr))));
    setLoading(false);
  };

  useEffect(() => {
    loadFarms();
  }, [farmAddresses]);

  const checkListings = async () => {
    if (!farms || farms.length === 0) {
      return;
    }
    console.log('checkListings');
    setListings([...(await Promise.all(farms.map(async (farm) => {
      console.log('listings', farm.farmNft);
      // todo: env
      const listingsContract = new readWeb3.eth.Contract(IListingGettersAbi as AbiItem[],
        '0x8b90560963cf5625384E6E642c4979e6A1C38B04');

      const listingAmount = await listingsContract.methods.getTokenListingAmount(farm.farmNft).call();

      return await Promise.all(range(0, parseInt(listingAmount, 10) + 1)
        .map(async (ele, i) => listingsContract.methods.getTokenListingByIndex(farm.farmNft, i).call()));
    }))).flat()]);
  };

  useEffect(() => {
    checkListings();
  }, [farms]);

  return (
    <div className="App">
      <Router>
        <h1><strong>MIVA</strong> Streaming <strong>Farms</strong></h1>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Connect>
              <Switch>
                <Route path="/list">
                  <PositionsList farms={farms} listings={listings} />
                </Route>
                <Route path="/position/:address/:id">
                  <PositionDetail farms={farms} listings={listings} />
                </Route>
                <Route path="/">
                  <Home farms={farms} listings={listings} />
                </Route>
              </Switch>
          </Connect>
        </Web3ReactProvider>
        <Link to="/">
          <div className="bgOwl"></div>
        </Link>

        <footer className="minervaGradient">
          <div className="inner">
            <div className="logo"><a href="https://minerva.digital" title="Minerva Wallet" target="blank">Minerva Wallet</a></div>

            <p className="socialLinks">
              <a className="twitter" target="_blank" rel="noreferrer" href="https://twitter.com/MinervaWallet" title="Follow @MinervaWallet on Twitter">Follow @MinervaWallet on Twitter</a>
              <a className="discord" target="_blank" rel="noreferrer" href="https://discord.gg/UzKcmMZNjm" title="Join the Minerva Discord-Server">Join the Minerva Discord-Server</a>
              <a className="telegram" target="_blank" rel="noreferrer" href="https://t.me/MinervaWallet" title="Minerva Wallet Group on Telegram">Minerva Wallet Group on Telegram</a>
              <a className="lens" target="_blank" rel="noreferrer" href="https://hey.xyz/u/0xMinervaWallet.lens" title="@0xMinervaWallet on .lens">@0xMinervaWallet on .lens</a>
              <a className="mirror" target="_blank" rel="noreferrer" href="https://mirror.xyz/minerva.eth" title="Minerva Wallet on mirror.xyz">Minerva Wallet on mirror.xyz</a>
              <a className="link3" target="_blank" rel="noreferrer" href="https://link3.to/MinervaWallet" title="Minerva Wallet Organization on link3.to mirror.xyz">Minerva Wallet Organization on link3.to mirror.xyz</a>
              <a className="snapshot" target="_blank" rel="noreferrer" href="https://snapshot.org/#/minerva.eth/" title="Minerva Wallet Snapshot DAO">Minerva Wallet DAO on Snapshot</a>
              <a className="github" target="_blank" rel="noreferrer" href="https://github.com/lab10-coop" title="Minerva Wallet Repo on Github">Minerva Wallet Repo on Github</a>
            </p>

            <div className="footerBottom">
              <p>© 2021-2023 by <a href="https://lab10.coop/imprint/" target="_blank" rel="noreferrer" title="lab10 collective">lab10 collective eG</a>. <br className="mobileOnly" />All rights reserved.</p>
            </div>
          </div>
        </footer>
      </Router>
    </div>
  );
}

export default App;
